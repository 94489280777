<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="card card-custom card-stretch gutter-b">
					<!--begin::Header-->
					<div class="card-header">
						<div class="card-title">
							<h3 class="card-label">Verifikasi PTSP</h3>
						</div>
					</div>
					<!--end::Header-->
					<!--begin::Body-->
					<div class="card-body pt-0">
						<!--begin::Item-->
						<div v-if="user.id_ptsp == 31">
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th style="font-size:14px;" class="text-left">Daerah</th>
											<th style="font-size:14px;" class="text-left">Dari Tanggal</th>
											<th style="font-size:14px;" class="text-left">Sampai Tanggal</th>
											<th style="font-size:14px;" class="text-left">Profil PTSP</th>
											<th style="font-size:14px;" class="text-left">Lihat PM PTSP</th>
											<th style="font-size:14px;" class="text-left">Unduh PM PTSP</th>
											<th style="font-size:14px;" class="text-left">Unduh File BA</th>
											<th style="font-size:14px;" class="text-left">Aksi</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item, index in dataptsp" :key="index">
											<td>
												<div v-if="item.kode_wilayah == 31">
													{{ item.nama_wilayah }}
												</div>
											</td>
											<td>
												<div v-if="item.kode_wilayah == 31">
													{{ format_date(item.tanggal_mulai) }}
												</div>
											</td>
											<td>
												<div v-if="item.kode_wilayah == 31">
													{{ format_date(item.tanggal_selesai) }}
												</div>
											</td>
											<td>
												<div v-if="item.kode_wilayah == 31">
													<b-button @click="profil(item.kode_wilayah)" size="sm"
														variant="success"><i class="flaticon-eye"></i></b-button>
												</div>
											</td>
											<td>
												<div>
													<b-button @click="urlptsp(item.kode_wilayah)"
														variant="success" size="sm" title="Lihat PM PTSP"><i
															class="flaticon2-list"></i></b-button>
												</div>
											</td>
											<td>
												<div v-if="item.kode_wilayah == 31">
													<b-button :href="exportexcell" @click="exportexcel(item.kode_wilayah)"
														variant="success" size="sm"><i
															class="flaticon2-download"></i></b-button>
												</div>
											</td>
											<td>
												<div v-if="item.kode_wilayah == 31">
													<button class="btn btn-success btn-sm mr-3"
														@click="urlba(item.kode_wilayah)"
														style="margin-top: 3px; margin-bottom: 3px;"><i
															class="flaticon2-download"></i></button>
												</div>
											</td>
											<td>
												<div v-if="item.kode_wilayah == 31">
													<button class="btn btn-primary btn-sm mr-3"
													 @click="verifikasi(item.kode_wilayah, item.nama_wilayah)"
														style="margin-top: 3px; margin-bottom: 3px;"><i
															class="flaticon-interface-5"></i> Verifikasi</button>
												</div>
											</td>
										</tr>
									</tbody>
									<!-- {{looping}} -->
								</template>
							</v-simple-table>
						</div>
						<div v-else>
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th style="font-size:14px;" class="text-left">Daerah</th>
											<th style="font-size:14px;" class="text-left">Dari Tanggal</th>
											<th style="font-size:14px;" class="text-left">Sampai Tanggal</th>
											<th style="font-size:14px;" class="text-left">Profil PTSP</th>
											<th style="font-size:14px;" class="text-left">Lihat PM PTSP</th>
											<th style="font-size:14px;" class="text-left">Unduh PM PTSP</th>
											<th style="font-size:14px;" class="text-left">Unduh File BA</th>
											<th style="font-size:14px;" class="text-left">Aksi</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item, index in dataptsp" :key="index">
											<td>{{ item.nama_wilayah }}</td>
											<td>
												{{ format_date(item.tanggal_mulai) }}
											</td>
											<td>
												{{ format_date(item.tanggal_selesai) }}
											</td>
											<td>
												<b-button @click="profil(item.kode_wilayah)" size="sm" variant="success"><i
														class="flaticon-eye"></i></b-button>
											</td>
											<td>
												<div>
													<b-button @click="urlptsp(item.kode_wilayah)"
														variant="success" size="sm" title="Lihat PM PTSP"><i
															class="flaticon2-list"></i></b-button>
												</div>
											</td>
											<td>
												<b-button :href="exportexcell" @click="exportexcel(item.kode_wilayah)"
													variant="success" size="sm"><i
														class="flaticon2-download"></i></b-button>
											</td>
											<td>
												<button class="btn btn-success btn-sm mr-3"
													@click="urlba(item.kode_wilayah)"
													style="margin-top: 3px; margin-bottom: 3px;"><i
														class="flaticon2-download"></i></button>
											</td>
											<td>
												<button class="btn btn-primary btn-sm mr-3"
													 @click="verifikasi(item.kode_wilayah, item.nama_wilayah)"
													style="margin-top: 3px; margin-bottom: 3px;"><i
														class="flaticon-interface-5"></i> Verifikasi</button>
											</td>
										</tr>
									</tbody>
									<!-- {{looping}} -->
								</template>
							</v-simple-table>
						</div>
						<br>
						<br>
						<!--end::Item-->
					</div>
					<!--end: Card Body-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import Swal from "sweetalert2";
import moment from 'moment'
export default {
	data() {
		return {
			url: localStorage.getItem('baseapi'),
			token: localStorage.getItem('id_token'),
			tahun: new Date().getFullYear(),
			user: JSON.parse(localStorage.getItem('user')),
			tanggal: '',
			exportexcell: '',
			exportexcellkl: '',
			selectedprov: "",
			file_bap: "",
			dataptsp: [],
			kota: [],
			headers: [
				{
					text: 'Nama',
					align: 'left',
					sortable: false,
					value: 'name',
				},
				{ text: 'Tanggal', value: '' },
				{ text: 'Aksi', value: '' },
			],
			pm: [],
			looping: [],
			status:{
				status_sistem: null
			}
		};
	},
	components: {
		KTCodePreview
	},
	mounted() {
		this.getNow()
		this.getkegiatan()
		this.getprovinsi()
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: "Verifikasi PTSP Pemda" }
		]);
		
	},
	methods: {
		getNow() {
			const today = new Date();
			this.tanggal = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		},
		getprovinsi() {
			console.log(this.user.penempatan)
			axios.get(this.url + '/penempatan?filter=id_user,=,' + this.user.id, {
				headers: {
					'xth': this.token
				}
			}).then(response => {
				if(this.user.id_ptsp == 31){
					const newArray = response.data.data.filter((value) => value.kode_wilayah == 31);
					this.dataptsp = newArray;
				}else{
					this.dataptsp = response.data.data
				}
				

			});
		},
		format_date(value) {
			if (value) {
				return moment(String(value)).format('DD-MM-YYYY')
			}
		},
		exportexcel(kode_wilayah) {
			window.open(this.url+`/export/pm_pemda/`+kode_wilayah+`/`+2024, '_blank')
		},
		exportexcelkl(kode_wilayah) {
			this.exportexcellkl = this.url + `/export/ppb_kl/${kode_wilayah}` + `/` + this.tahun
		},
		urlba(kode_wilayah) {
			axios.get(this.url + `/verifikasi_ptsp_pemda_2023?filter=idptsp,=,${kode_wilayah};tahun,=,${this.tahun}`, {
				headers: {
					'xth': this.token
				}
			}).then(response => {
				let ba = response.data.data[0].file_ba;

				//console.log(response)

				if (ba == null) {
					Swal.fire({
						title: "",
						text: "File BA Belum ada",
						icon: "info",
						confirmButtonClass: "btn btn-secondary"
					});
				} else {
					window.open(ba, '_blank')
				}

			});
		},
		getkota(id) {
			axios.get(this.url + `/kabkota/${id}`, {
				headers: {
					'xth': this.token
				}
			}).then(response => {
				this.kota = response.data;
			});
		},
		verifikasi(idkabkota, nama_wilayah){
			axios.get(this.url + `/tanggal_kegiatans?filter=tanggal_mulai,<=,` + this.tanggal + `;tanggal_selesai,>=,` + this.tanggal, {
				headers: {
					'xth': this.token
				}
			}).then(response => {
				//console.log(response)
				//this.status.status_sistem = "Tutup Permanen";
				this.status = response.data.data;
				this.getidkabkota(idkabkota, nama_wilayah)
			}).catch(error => {
				console.log(error)
				return error
			});
		},
		getidkabkota(idkabkota, nama_wilayah) {
			localStorage.setItem('idptsp', idkabkota)
			localStorage.setItem("nama_wilayah", nama_wilayah);
			console.log(nama_wilayah)
			axios.get(this.url + `/pm_ptsp_pemda_2023?filter=idptsp,=,${idkabkota};tahun,=,` + this.tahun, {
				headers: {
					'xth': this.token
				}
			})
				.then(response => {
					this.pm = response.data.data;

					if (this.pm.length == 0) {
						Swal.fire({
							title: "",
							text: "Provinsi / kabupaten / kota ini belum melakukan penilaian",
							icon: "info",
							confirmButtonClass: "btn btn-secondary"
						});
					} else {

						axios.get(this.url + `/verifikasi_ptsp_pemda_2023?filter=idptsp,=,${idkabkota};tahun,=,${this.tahun}`, {
							headers: {
								'xth': this.token
							}
						}).then(response => {
							let ba = response.data.data[0].file_ba;

							if (ba != null) {
								Swal.fire({
									title: "Info!",
									width: 600,
									heightAuto: true,
									html:
										'<div sytle="text-align: left;">Yth. Para Surveyor</div> <br> <div sytle="text-align: left;"> Penilaian ini sudah disetujui dan berita acara telah terbit, silahkan hubungi helpdesk</div>',
									icon: "info",
									confirmButtonClass: "btn btn-secondary"
								});
							} else {

								console.log(this.status);

								localStorage.setItem('idpm', JSON.stringify(this.pm.id))
								if (this.status.status_sistem === 'Buka') {
									Swal.fire({
										title: "",
										text: "Penilaian Mandiri saat ini telah dibuka, Anda tidak dapat membuka Penilaian",
										icon: "info",
										confirmButtonClass: "btn btn-secondary"
									});
								}else if(this.status.status_sistem === 'Tutup Sementara'){
									this.$router.push({ name: "ptsppemda-verdok" })
								}else if(this.status.status_sistem === 'Tutup Permanen'){
									this.$router.push({ name: "ptsppemda-detail" })
								}else{
									Swal.fire({
										title: "",
										text: "Tidak ada kegiatan penilaian dan verifikasi. Terima kasih!",
										icon: "info",
										confirmButtonClass: "btn btn-secondary"
									});
								}
							}

						});
						// Swal.fire({
						// title: "Info!",
						// width: 600,
						// heightAuto: true,
						// html:
						// 	'<div sytle="text-align: left;">Yth. Para Surveyor</div> <br> <div sytle="text-align: left;"> Berdasarkan petunjuk dari pimpinan Kementerian Investasi/BKPM dan saran dari Ketua Tim Ahli, dimohon kepada para Surveyor untuk melakukan pengecekan lebih teliti, untuk indikator-indikator yang diblok dengan warna kuning</div> <br> <div sytle="text-align: left;">Ketua Tim Penilai<div>',
						// icon: "info",
						// confirmButtonClass: "btn btn-secondary"
						// });
						// localStorage.setItem('idpm', JSON.stringify(this.pm.id))
						// this.$router.push({ name: "ptsppemda-detail" })
					}
				}).catch(error => {
					console.log(error)
					return error
				});
		},
		profil(kode_wilayah) {
			let routeData = this.$router.resolve(
				{
					path: `/list-peserta/profilptspdetail/${kode_wilayah}`,
				});
			window.open(routeData.href, '_blank');
		},
		countDownChanged1(dismissCountDown) {
			this.code1.dismissCountDown = dismissCountDown;
		},
		countDownChanged2(dismissCountDown) {
			this.code2.dismissCountDown = dismissCountDown;
		},
		showAlert1() {
			this.code1.dismissCountDown = this.code1.dismissSecs;
		},
		showAlert2() {
			this.code2.dismissCountDown = this.code2.dismissSecs;
		},
		getkegiatan() {
			axios.get(this.url + `/tanggal_kegiatans?filter=tanggal_mulai,<=,` + this.tanggal + `;tanggal_selesai,>=,` + this.tanggal, {
				headers: {
					'xth': this.token
				}
			}).then(response => {
				//console.log(response)
				//this.status.status_sistem = "Tutup Permanen";
				this.status = response.data.data;

				this.notifikasi()
			}).catch(error => {
				console.log(error)
				return error
			});
		},
		notifikasi() {
			//   e.preventDefault();
			if (this.status.status_sistem === 'Buka') {
				Swal.fire({
					title: "",
					text: "Penilaian Mandiri saat ini telah dibuka",
					icon: "info",
					confirmButtonClass: "btn btn-secondary"
				});
			} else if (this.status.status_sistem === 'Tutup Sementara') {
				Swal.fire({
					title: "",
					text: "Penilaian Mandiri saat ini telah ditutup sementara",
					icon: "info",
					confirmButtonClass: "btn btn-secondary"
				});
			} else {
				Swal.fire({
					title: "",
					text: "Penilaian Mandiri saat ini telah ditutup",
					icon: "info",
					confirmButtonClass: "btn btn-secondary"
				});
			}
		},

		urlptsp(kode_wilayah) {
		let routeData = this.$router.resolve(
			{
			path: `/verifikasi/detailpm/${kode_wilayah}`,
			// query: {'dataParameter': kode_wilayah}
			});
		window.open(routeData.href, '_blank');
		//                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
		``
		},
	}
};
</script>